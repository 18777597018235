.contact-form{

    margin-bottom: 30px;

    .container{
        @include default-container();

        @media #{$tablet} {
            box-sizing: content-box;
            padding: 0;
        }
    }

    &__container{
        background-color: $colorbase;
        color: #fff;
        padding: 50px;
        
        @media #{$tablet}{
            padding: 30px 20px;
        }
    }

    &__title{
        display: block;
        text-align: center;
        font-size: 35px;
        font-weight: normal;
        text-transform: uppercase;

        @media #{$tablet}{
            font-size: 25px;   
        }
    }

    .form{
        width: 100%;
        max-width: 970px;
        padding: 0 15px;
        margin: 0 auto;
        box-sizing: border-box;

        &__block{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
        }

        &__label{
            letter-spacing: 1.5px;
        }

        &__input{
            height: 50px;
            width: 100%;
            padding: 5px;
            box-sizing: border-box;
            max-width: 970px;
            border: none;
            color: $colorbase;
            text-align: center;
            outline: none;

        }
        textarea{
            height: 150px;
        }

        &__submit{
            height: 50px;
            width: 100%;
            padding: 5px;
            letter-spacing: 1.5px;
            background-color: $ctaform;
            border: none;
            outline: none;
            transition: ease .2s;

            &:hover{
                background-color: #fff;
                color: $ctaform;
            }
        }
    }

    &--negative{
        position: relative;
        margin-top: -150px;

        @media #{$tablet}{
            margin-top: 0px;
        }
    }

}