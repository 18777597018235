.sorrisosqualicenter{

    padding: 50px;
        
        @media #{$tablet}{
            padding: 10px 0px;
        }

    .container{
        @include default-container();
    }

    &__container{

    }

    &__title{
        font-size: 50px;
        font-weight: normal;
        color: $colorvariation;
        letter-spacing: 1.5px;

        @media #{$tablet} {
            font-size: 25px;
        }
    }

    &__boxes{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        
    }

    &__box{
        flex-basis: 23%;

        @media #{$tablet}{
            flex-basis: 48%;
        }
    }
    &__image{
        width: 100%;
    }

    &__info{
        padding: 0 5px; 
        margin-top: 15px;
        margin-bottom: 30px;
    }

    &__text{
        margin: 0px;
        padding: 2px 0;
        @include textgray();

        @media #{$tablet}{
            line-height: 20px;   
        }

        strong{
            color: $colorbase;
        }
    }

}