.boxnumero{
    flex-basis: 33%;

    @media #{$tablet}{
        max-width: 430px;
        margin-bottom: 40px;
    }

    img{
        width: 100%;
    }

    &:nth-child(2){
        margin-left: 40px;
        margin-right: 40px;

        @media #{$tablet}{
            margin-left: 0px;
            margin-right: 0px;
        }
    }

    &__text{
        @include textgray();

        @media #{$tablet}{
            line-height: 20px;   
        }
    }
}