
.boxtitle{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    
    &__box{
        flex-basis: calc(33% - 20px);
        padding: 10px;

        @media #{$tablet}{
            flex-basis: calc(50% - 10px);
            padding: 5px;
            text-align: center;
        }

        &:hover{

            .boxtitle__image{
                transform: scale(1.05);
            }

            .boxtitle__description{
                background-color:#fff ;
                color: $colorbase;
                border: solid 1px $colorbase;
            }

        }
    }

    &__box-25{
        flex-basis: calc(25% - 20px);
        padding: 10px;

        @media #{$tablet}{
            flex-basis: calc(50% - 20px);
            text-align: center;
        }
    }

    &__imagebox{
        padding: 0 10px;
        overflow: hidden;

        @media #{$tablet}{
            padding: 0px;
        }
    }

    &__image{
        width: 100%;
        transform: scale(1);
        transition: ease .3s;
    }

    &__description{
        background-color:$colorbase ;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        transition: ease .3s;
    }

    &__text{
        letter-spacing: 1.5px;
        @media #{$tablet}{
            font-size: 12px;
            line-height: 20px;   
        }
    }
}