@import 'variables.scss';
@import 'mixins.scss';
@import 'header.scss';
@import 'footer.scss';

body{
    font-family: $fdefault;
    font-size: $fsize;
    padding-top: 0px;

    @media #{$tablet}{
        padding-top: 0px;
        font-size: $fsizemobile;
    }
}

.background{
    background-image: url(../img/bg-sintomas.png);
    background-repeat: no-repeat;
    background-position: top left;
    background-size: contain;
}

.overlay{
    position: relative;
}
.overlay:before {
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    background: $colorbase;
    opacity: 0.9;
}
.table-responsive {
    overflow-x: scroll;
}
.tg  {border-collapse:collapse;border-spacing:0;}
.tg td{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;
  overflow:hidden;padding:10px 5px;word-break:normal;}
.tg th{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;
  font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}
.tg .tg-2xh0{background-color:$colorbase;text-align:left;vertical-align:top; color: #fff;}
.tg .tg-0lax{text-align:left;vertical-align:top}