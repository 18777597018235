@import 'normalize.scss';
@import 'base/default.scss';
@import 'common/banner.scss';
@import 'common/questions.scss';
@import 'common/boxnumero.scss';
@import 'common/form.scss';
@import 'common/boxtitle.scss';
@import 'common/sorrisosqualicenter.scss';

.testimonials{
    background-image: url(../img/bd-testimonials.png);
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 100px;
    margin-bottom: 30px;

    .container{
        @include default-container();
        position: relative;
    }

    &__title{
        font-size: 45px;
        color: #fff;
        display: block;
        text-align: center;
        width: 100%;
        letter-spacing: 2px;
        text-transform: uppercase;
        margin-bottom: 20px;

        @media #{$tablet} {
            font-size: 25px;
        }
    }

    &__slick{
        display: block;
        overflow: initial;
        position: relative;

        .slick-list {
            overflow: hidden;
            width: 75%;
            margin: 0 auto;

            @media #{$mobile} {
                width: 72%;
            }
        }

        .slick-arrow {
            background: none;
            border: none;
            
            &:focus{
                outline: none;
            }

            svg {
                width: 80px;
                height: 80px;

                @media #{$mobile} {
                    width: 50px;
                    height: 50px;
                }

                path {
                    fill: rgba(255, 255, 255, 0.39);
                }
                g{
                    path.cls-1 {    
                        stroke: $colorbase;
                        stroke-width: 5px;
                        fill: transparent;
                    }
                }
            }
        }
        .slick-next {
            transform: rotate(-90deg);
            top: calc(50% - 40px);
            position: absolute;
            right: 0px;
        }
        
        .slick-prev {
            transform: rotate(90deg);
            top: calc(50% - 40px);
            position: absolute;
        }
    }

    &__container{
        display: block;
        overflow: hidden;
        max-width: 1000px;
        margin: 0 auto;
        box-sizing: border-box;
    }

    &__text{
        color: #fff;
        display: inline-flex;
        line-height: 35px;
        letter-spacing: 1.2px;

        @media #{$tablet} {
            font-size: 16px;
            line-height: 25px;
        }
    }
}

.sobre{

    .container{
        @include default-container();
    }

    &__container{
        padding: 50px;
        
        @media #{$tablet}{
            padding: 0px;
        } 

        @media #{$tablet}{
            padding-top: 10px;
            margin-bottom: 40px;
        }
    }

    &__title{
        font-size: 50px;
        font-weight: normal;
        color: $colorvariation;
        letter-spacing: 1.5px;

        @media #{$tablet} {
            font-size: 25px;
        }
    }

    &__text{
        @include textgray();
        
        @media #{$tablet}{
            line-height: 20px;
        }
    }
}

.sintomas{

    .container{
        @include default-container();
    }

    &__container{

        padding: 50px;
        
        @media #{$tablet}{
            padding-left: 0px;
            padding-right: 0px;
            padding-top: 10px;
            padding-bottom: 20px;

        }
        
    }

    &__title{
        font-size: 50px;
        font-weight: normal;
        color: $colorvariation;
        letter-spacing: 1.5px;

        @media #{$tablet}{
            font-size: 25px;
            padding-bottom: 40px;
            padding-top: 20px;
            text-align: left;
        }
    }

    &__boxes{
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        flex-direction: row;

        @media #{$tablet}{
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
        }

        
    }
    &__text{
        @include textgray();

        @media #{$tablet}{
            line-height: 20px;   
        }
    }
    &__item{
        @include textgray();
        margin-bottom:5px ;
    }
}

.diferenciais{
    display: flex;
    flex-wrap: wrap;
    
    &__container{
        flex-basis: 45%;
        display: block;
        margin: 0px;

        @media #{$tablet} {
            flex-basis: 100%;
        }
    }
    &__item{
        flex-basis: 50%;
        @include textgray();
        text-transform: uppercase;
        margin-bottom: 30px;

        @media #{$tablet} {
            flex-basis: 100%;
        }
    }
}

.convenios{
    .container{
        @include default-container();
    }

    &__title{
        font-size: 50px;
        font-weight: normal;
        color: $colorvariation;
        letter-spacing: 1.5px;

        @media #{$tablet}{
            font-size: 25px;
            padding-bottom: 40px;
            padding-top: 20px;
            text-align: left;
        }
    }

    &__content{
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        justify-content: flex-start;

        img{
            max-width: 25%;
            margin-bottom: 20px;

            @media #{$tablet}{
                max-width: 33%;
            }

            @media #{$mobile}{
                max-width: 50%;
            }
        }
    }
}

.nossaequipe{

    padding: 50px;

    @media #{$tablet} {
        padding-left: 0px;
        padding-right: 0px;
    }

    .container{
        @include default-container();
    }
    &__grid{
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;

        @media #{$tablet} {
            grid-template-columns: 50% 50%;
        }
    }
    &__image{
        padding: 10px 10px 15px 10px;
        position: relative;

        &:before{
            content: '';
            display: block;
            width: 100%;
            height: 10px;
            background-color: $colorbase;
            margin-bottom: 15px;
        }

        img{
            width: 100%;
            transition: ease-out .2s;
            transform: scale(1);
        }

        &:hover{
            img{
                width: 100%;
                transform: scale(1.1);
            }   
            .nossaequipe__info{
                opacity: 1;
            }
        }
    }

    &__title{
        font-weight: bold;
        font-size: 50px;
        letter-spacing: 1.2px;
        margin-bottom: 60px;
        color: $colorvariation;

        @media #{$tablet} {
            font-size: 25px;
        }
    }
    &__content{
        position: relative;
        overflow: hidden;
        font-size: 13px;
    }

    &__info{
        background: rgba(131, 22, 65, 0.9);
        padding: 5px 15px;
        color: #fff;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        box-sizing: border-box;
        display: flex;
        height: 100%;
        flex-direction: column;
        transition: ease-out .2s;
        padding-top: 30px;
        line-height: 17px;
        opacity: 0;
        overflow-y: auto;

        p{
            margin-top: 0px;
            margin-bottom: 5px;

            &:first-child{
                font-weight: bold;
                margin-bottom: 15px;
                text-transform: uppercase;
            }
        }
    }
}