.banner{
    
    position: relative;
    overflow: hidden;
    background-position: 100% 0%;
    height: 600px;
    background-size: cover;
    margin-bottom: 30px;

    @media #{$tablet} {
        height:400px
    }

    @media #{$mobile} {
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
        margin-bottom: 10px;
        height: 300px;
    }
    
    &--invert{
        background-position: 0% 0%;

        .banner__container{
            margin-left: 50%;
            
            @media #{$tablet} {
                margin-left: auto;    
                align-items: flex-end;
                text-align: right;        
            }
            @media #{$mobile} {    
                align-items: center;
                text-align: center;        
            }
        }
    }


    .container{
        @include default-container();
        position: relative;
        z-index: 1;

        @media #{$mobile} {
            order: 2;
            position: absolute;
            height: 100%;
            display: flex;
            background: rgba(255, 255, 255, 0.64);
            margin: 0px;
        }
    }

    &__container{

        width: 70%;
        display: flex;
        height: 100%;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        height: 100%;

        @media #{$tablet} {
            width: 80%;
        }
        @media #{$mobile} {
            width: 100%;
            height: auto;
            text-align: center;
            align-items: center;
        }

        h2{
            color: $colorvariation;
            font-weight: normal;
            text-transform: uppercase;
            font-size: 60px;
            letter-spacing: 1.5px;
            margin-bottom: 10px;
            margin-top: 0px;

            @media #{$mobile} {
                font-size: 25px;
                width: 80%;
            }

            strong{
                font-weight: bold;
            }
        }
        h3{
            color: $colorbase;
            text-transform: uppercase;
            font-size: 40px;
            letter-spacing: 1.5px;
            margin-bottom: 10px;
            margin-top: 15px;

            @media #{$mobile} {
                font-size: 25px;
                width: 80%;
            }
        }
    }

    &__cta{
        background-color: $colorbase;
        border: none;
        border-radius: 0px;
        height: 50px;
        width: 100%;
        max-width: 340px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        transition: ease-in-out .2s;
        text-decoration: none;

        &:active{
            color: $colorbase;
            background-color: #fff;
            border: solid 2px $colorbase;
        }
    }

    

    &-sorriso{
        background-image: url(../img/banner-sorriso.jpg);
        // @media #{$mobile} {
        //     background-image: none;    
        // }
    }
    &-dontiaum{
        background-image: url(../img/banner-dontiaum.jpg);
        // @media #{$mobile} {
        //     background-image: none;    
        // } 
    }
    &-dontiadois{
        background-image: url(../img/banner-dontiadois.jpg);
        // @media #{$mobile} {
        //     background-image: none;    
        // } 
    }
    &-atm{
        background-image: url(../img/banner-atm.jpg);
        // @media #{$mobile} {
        //     background-image: none;    
        // }
    }
    &-estetica{
        background-image: url(../img/banner-estetica.jpg);
        // @media #{$mobile} {
        //     background-image: none;    
        // }
    }
    &-estetica2{
        background-image: url(../img/banner-estetica2.jpg);
        // @media #{$mobile} {
        //     background-image: none;    
        // }
    }
    &-cirurgias{
        background-image: url(../img/banner-cirurgias.jpg);
        // @media #{$mobile} {
        //     background-image: none;    
        // }
    }
    &-contato{
        background-image: url(../img/banner-contato.jpg);
        // @media #{$mobile} {
        //     background-image: none;    
        // }
    }
    &-endodontia{
        background-image: url(../img/banner-endodontia.jpg);
        // @media #{$mobile} {
        //     background-image: none;    
        // }
    }
    &-implantes{
        background-image: url(../img/banner-mplantes.jpg);
        // @media #{$mobile} {
        //     background-image: none;    
        // }
    }
    &-odontopediatria{
        background-image: url(../img/banner-dontopediatria.jpg);
        // @media #{$mobile} {
        //     background-image: none;    
        // }
    }

    &__image{
        display: none;

        @media #{$mobile} {
            display: none;   
        }

        img{
            width: 100%;
        }

        @media #{$mobile} {
            order: 1;
        }
    }
}