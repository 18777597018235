@import url('https://fonts.googleapis.com/css?family=Asap:400,700|Montserrat:400,700&display=swap');


$fdefault: 'Montserrat', sans-serif;
$fsize:16px;
$fsizemobile:12px;
$colorbase: #831641;
$colorvariation:#001339;
$colorsupport:#9a9a9a;
$colorgray:#575757;
$ctaform:lighten( $colorbase, 14% );
$hover:lighten( $colorbase, 58% );

$tablet: '(max-width:980px)';
$mobile: '(max-width:768px)';