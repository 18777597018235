
.header {
    background: $colorbase;
    height: initial;
    max-height: 112px;
    padding: 10px 0px;
    box-sizing: border-box;
    background-image: url(/_min/img/bg-header.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 100;
    width: 100%;
    transition: all ease-in-out .2s;
    top: 0px;
    
    &.overlay{
        position: relative !important;
    }

    @media #{$tablet} {
        height: initial;
        padding: 10px 0px;
        position: fixed;
        width: 100%;
        top: 0px;
        left: 0px;
        z-index: 100;
        max-height: 84px;
    }

    .container{
        @include default-container();
        position: relative;

        
    }

    &__logo{

        @media #{$tablet}{
            order: 2;  
            flex-basis: 100%; 
            text-align: center;
            margin-left: -20%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        
        img{
            width: 100%;
            max-width: 150px;
            transition: ease-in-out .2s;

            @media #{$tablet}{
                width: 100%;
                max-width: 85px;
            }
        }
    }

    &__menu{
       
        @media #{$tablet}{
            order: 1;  
            flex-basis: 20%;
            max-height: 64px;
            z-index: 10;
        }
    }

    &__container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        
        @media #{$tablet}{
            max-height: 64px;
        }
        
    }

    .menu{

        svg{
            path{
                fill:#fff
            }
        }

        &__container{
            @media #{$tablet} {
                position: fixed;
                left: -100%;
                top: 0;
                overflow-y: auto;
                background: #fff;
                height: 100vh;
                width: 80%;
                max-width: 350px;
                transition: ease-in-out .3s;
                box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.22);

                .mask {
                    display: none;
                    // position: absolute;
                    // width: 100%;
                    // height: 100vh;
                    // display: block;
                    // top: 0;
                    // right: -100%;
                    // pointer-events: none;
                }

            }

            &--active{
                left: 0%;

                .mask {
                    pointer-events: auto;
                }
            }
        }

        &__hamburger{
            display: none;
            
            @media #{$tablet}{
                display: flex;
                height: initial;
                align-items: center;
            }            
        }

        &__close{
            display: none;
            
            @media #{$tablet}{
                display: block;
                position: absolute;
                right: 5px;
                top: 5px;
                z-index: 7;

                svg{
                    path{
                        fill:$colorbase
                    }
                }
            
            }
            
            
        }

        &__navigation{
            
            @media #{$tablet}{
                z-index: 6;
                position: relative;
                background: #fff;
                padding-top: 0px;
            }

            ul.nivel1{
                list-style: none;
                margin: 0px;
                padding: 0px;
                display: flex;
                height: initial;

                @media #{$tablet}{
                    display: block;
                }

                li{
                    height: initial;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    
                    @media #{$tablet}{
                        height: auto;
                    }

                    &:after{
                        content: '|';
                        display: block;
                        padding-right: 15px;
                        color: #fff;
    
                        @media #{$tablet}{
                            display: none;
                        }
                    }

                    &:last-child{
                        &:after{
                            display: none;
                        }
                    }
                }
            }

            a{
                color: #fff;
                display: flex;
                margin-right: 15px;
                text-decoration: none;
                transition: ease-in-out .2s;
                padding: 5px;

                @media #{$tablet}{
                    color: $colorbase;
                    text-align: left;
                    display: block;
                    padding: 25px 15px;
                    margin-right: 0px;
                    width: 100%;
                    font-weight: bold;
                }

                &:hover{
                    color: $hover;
                }
            }
        }

        &__submenu{
            display: none;
            position: absolute;
            top: 100%;
            background: #fff;
            padding-top: 30px;
            padding-bottom: 30px;
            padding-left: 15px;
            padding-right: 15px;

            @media #{$tablet}{
                position: relative;
                top: 0px;
                width: 100%;
                background: rgb(232, 232, 232);
                padding: 0px;
            }

            li{
                &:after{
                    display: none !important;
                }
                
                a{
                    color: $colorbase;
                    margin-right: 0px;
                    flex-basis: 100%;
                }
            }
        }

        &__dropdown{

            @media #{$tablet}{
                flex-wrap: wrap;
                justify-content: space-between !important;
            }

            a{
                @media #{$tablet}{
                    flex-basis: 70%;
                }
            }

            svg{
                width: 25px;
                height: 25px;
                margin-left: -15px;
                margin-right: 15px;
                transform: rotate(0deg);
                transition: ease .2s;
                
                @media #{$tablet}{
                    flex-basis: 15%;
                }

                polygon{
                    fill: #fff;
                }
                @media #{$tablet}{
                    polygon{
                        fill: $colorbase;
                    }
                }
            }

            &--active{

                svg{
                    transform: rotate(180deg);
                }

            }
        }

    }

    &--fixed{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 100;
        padding: 10px 0px;

        img{
            width: 100%;
            max-width: 100px;
        }
    }
}