@mixin default-container($width: 1200px) {
    width: 100%;
    max-width: $width;
    padding: 0 15px;
    margin: 0 auto;
    box-sizing: border-box;
    
}

@mixin textgray {
    color: $colorgray;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    line-height: 30px;
}