.footer{
    background-color: $colorsupport;
    padding: 50px 0px;
    
    .container{
        @include default-container();
    }

    &__container{
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        width: 100%;

        @media #{$tablet} {
            flex-direction: column;
            align-items: center;
            text-align: center;
        }
    }
    &__text{
        color: #fff;
        margin: 0px;
        padding-bottom: 3px;

        a{
            color: #fff;
            text-decoration: none;
        }
    }

    &__col{

        @media #{$tablet} {
            margin-bottom: 40px;
        }

        img{
            margin-bottom: 40px;
        }

        address{
            margin-bottom: 20px;
            font-style: normal;
        }
    }
}

.socialmedia{
    background-color: $colorbase;
    padding: 50px 0px;

    .container{
        @include default-container();
    }

    &__container{
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        @media #{$mobile}{
            flex-direction: column;   
        }
    }

    &__link{
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        text-decoration: none;

        @media #{$mobile}{
            width: 100%;
            margin-bottom: 10px;   
        }
        svg{
            width: 50px;
            height: 50px;
            margin-right: 10px;
    
            path{
                fill: #fff
            }
    
            path[clip-rule='evenodd']{
                fill: $colorbase
            }
        }

        span{
            width: 100%;
            max-width: 200px;
        }
    }

    
}

.whatschat.social__whatsapp {
    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 100;
    width: 50px;
    height: 50px;
}
