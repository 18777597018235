.perguntas{

    .container{
        @include default-container();
    }

    &__container{
        padding: 50px;
        
        @media #{$tablet}{
            padding: 0px;
            margin-bottom: 50px;
        }
    }

    &__title{
        font-size: 50px;
        color: $colorvariation;
        letter-spacing: 1.6px;

        @media #{$tablet}{
            font-size: 25px;
            padding-bottom: 40px;
            padding-top: 20px;
            text-align: left;
        }
    }

    &__aswer{
        @include textgray();
        display: none;
        
        @media #{$tablet}{
            line-height: 20px;
            padding: 0 15px;   
        }
    }

    &__ask{
        color: $colorvariation;
        letter-spacing: 1.2px;
        text-transform: uppercase;
        font-size: 20px;
        cursor: pointer;

        @media #{$tablet}{
            font-size: 15px;
            line-height: 20px;   
        }
    }
}